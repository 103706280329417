import { Theme } from '@mui/material/styles';
import typography from '../typography';

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ...typography.body2,
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          '@media (max-width: 600px)': {
            minHeight: 0,
          },
        },
      },
    },
  };
}

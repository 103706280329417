import { Theme, alpha } from '@mui/material/styles';
import typography from '../typography';

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  const boxShadow = `0 0 2px 0 ${alpha(isLight ? theme.palette.grey[500] : theme.palette.common.black, 0.2)}, ${
    theme.customShadows.z20
  }`;

  return {
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          ...typography.body2,
        },
        paper: {
          boxShadow,
          '& .MuiAutocomplete-noOptions': {
            ...typography.body2,
            padding: theme.spacing(1),
          },
        },
        listbox: {
          padding: theme.spacing(0, 1),
          '& .MuiAutocomplete-option': {
            padding: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            ...typography.body2,
            [theme.breakpoints.down('md')]: {
              minHeight: 0,
            },
          },
        },
      },
    },
  };
}

import { Theme } from '@mui/material/styles';
import typography from '../typography';

// ----------------------------------------------------------------------

export default function FormControl(theme: Theme) {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-sizeSmall:not(.Mui-focused)': {
            ...typography.body2,
          },
        },
      },
    },
  };
}
